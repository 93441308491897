<template>
    <div>
        <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#4480C5" size="60" is-full-screen />
        <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                    <span style="color: #ffffff">
                        {{ msg }}
                    </span>
                </v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: #ffffff">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>

        <v-layout wrap justify-center>
            <v-flex pt-5 xs11 sm11 md11 lg11 xl12>

                <!-- Header Section -->
                <v-layout wrap justify-start class="my-3">
                    <v-flex xs12 sm3 md3 lg10 text-start align-center pt-2 class="headingChurch">Feedbacks</v-flex>
                    <!-- <v-flex xs12 sm9 md9 lg2 text-end align-center><v-btn class="churchbtn" color="blue"
                            style="color:white" @click="dialog = true">ADD DIOCESE</v-btn></v-flex> -->
                </v-layout>


                <!-- Table Section -->

                <v-layout wrap justify-center pb-3>
                    <v-flex xs12>
                        <v-data-table :headers="headers" :items="feedbacklist" hide-default-footer
                            class="elevation-1 text--black" id="virtual-scroll-table">
                            <template v-slot:[`item.createdAt`]="{ item }">
                                <span>{{ formatDate1(item.createdAt) }}</span>
                            </template>

                            <template v-slot:[`item.feedback`]="{ item }">
                                <div style="cursor: pointer" class="truncated"
                                    @click.stop="showFullDescription(item.feedback)">
                                    {{ item.feedback.slice(0, 40) }}<span v-if="item.feedback.length > 20">....</span>
                                </div>
                            </template>

                            <template v-slot:[`item._id`]="{ item }">
                               
                                <v-icon small color="red" class="ml-1" @click.stop="openDeleteDialog(item)">
                                    mdi-delete
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-flex>
                </v-layout>
                <!-- pagination -->
                <v-layout wrap justify-center pt-2>
                    <v-flex xs12>
                        <div class="text-center pb-5" v-if="pages > 1">
                            <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                                circle></v-pagination>
                        </div>
                    </v-flex>
                </v-layout>

                <!-- description dialog-box -->

                <v-dialog v-model="dialogdes" max-width="600px">
                    <v-card>
                        <v-card-title class="card-header">Full Feedback</v-card-title>
                        <v-card-text class="text-des pt-3">
                            {{ fullDescription }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn style="font-family: interregular; font-size: 13px; color: white;"
                                color="blue darken-1" @click="dialogdes = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                  <!-- delete dialog -->
        <v-dialog v-model="deletedialog" max-width="600">
            <v-card class="dialog-card">
                <v-card-title class="grey lighten-2 d-flex justify-center">
                    <v-icon color="red" size="32">mdi-alert</v-icon>
                    <span class="ml-2">Confirm Deletion</span>
                </v-card-title>
                <v-card-text class="py-5 text-center text-des">
                    <div class="body-1">
                        Are you sure you want to delete this Diocese?
                        <br />
                        This action <strong>cannot be undone</strong>.
                    </div>
                    <v-divider class="my-3"></v-divider>
                    <v-row class="pt-2" no-gutters>
                        <v-col>
                            <img src="@/assets/images/churchb.png" width="40px" height="40px" class="dialog-icon" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pa-2">
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="#cf3a45"
                        @click="deletedialog = false">Cancel</v-btn>
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="blue darken-1"
                        @click="confirmDelete">OK</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

            </v-flex>
        </v-layout>

    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            showSnackBar: false,
            timeout: 5000,
            msg: "",
            //rite:[],
            appLoading: false,
            deletedialog: false,
            // dialog: false,
            dialogdes: false,
            fullDescription: '',
            feedbacklist: [],
            page: 1,
            currentPage: 1,
            pages: 0,
            limit: 10,
            headers: [
                { text: "Name", value: "user.name", width: "200px" },
                { text: "Email", value: "user.email", width: "250px" },
                { text: "Feedback", value: "feedback", width: "200px" },
                { text: "Created Date", value: "createdAt", width: "200px" },
                //{ text: "Location", value: "locationName", width: "110px" },
                // { text: "Diocese", value: "diocese.name", width: "10px" },
                // // { text: "Parish Type", value: "parishType.name", width: "90px" },
                // { text: "Country", value: "address.country", width: "10px" },
                 { text: "Actions", value: "_id", width: "5px", }

            ],

        };
    },
    mounted() {
        this.getData(); // Fetch data when component mounts
        //this.getRite();
    },
    watch:{

currentPage() {
  
this.getData();

}
},
   
    methods: {
        //     closeDialog() {
        //   this.editdialog = false;
        //   this.getData();
        // },
        formatDate1(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB').replace(/\//g, '-');
        },

        showFullDescription(feedback) {
            this.fullDescription = feedback;
            this.dialogdes = true;
        },
        getData() {
            this.appLoading = true;
            axios({
                url: "/get/all/feedback",

                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
                params: {
                    limit: this.limit,
                    page: this.currentPage,

                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.feedbacklist = response.data.data;
                    this.pages = Math.ceil(response.data.totalLength / this.limit);
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        openDeleteDialog(item) {
            this.itemToDelete = item;
            this.deletedialog = true;
        },
        confirmDelete() {
            if (this.itemToDelete) {
                this.deleteItem(this.itemToDelete);
            }
            this.deletedialog = false;
        },
        deleteItem(r) {
            var data = {};
            data["id"] = r._id;
            axios({
                url: "/delete/feedback",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
            this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
            this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },



    }
};
</script>

<style scoped>
.dialog-card {
    font-family: interbold;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
    animation: pulse 1s infinite alternate;
}

.dialog-button {
    min-width: 120px;
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0.7;
    }

    to {
        transform: scale(1.1);
        opacity: 1;
    }
}

.table-responsive {
    overflow-x: auto;

}
</style>